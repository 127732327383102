/* eslint-disable */
import React, { Component } from 'react'
import MailchimpSubscribe from 'react-mailchimp-subscribe'
import { Flex, Box } from 'reflexbox'
import ButtonBorder from 'src/components/UI/Button/ButtonBorder/ButtonBorder'
import CheckBox from 'src/components/UI/Checkbox/Checkbox'
import Markdown from 'src/components/Markdown/Markdown'
import { gtagEvent } from 'src/components/UI/GoogleAnalytics/GoogleAnalytics'
import * as styles from './MailchimpForm.module.scss'
import { useLocation } from '@reach/router'

class CustomForm extends Component {
  state = {
    status: '',
    message: '',
    firstName: '',
    lastName: '',
    email: '',
    validEmail: false,
    acceptTerms: false,
  }

  validateEmail = (event: any) => {
    const email = event.target.value
    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    const isValid = re.test(String(email).toLowerCase())

    this.setState({ email: email, validEmail: isValid })
  }

  componentDidUpdate(nextProps) {
    if (nextProps.status !== this.props.status) {
      if (this.props.status) {
        this.setState({ status: this.props.status })
      }
    }
    if (nextProps.message !== this.props.message) {
      if (this.props.message) {
        this.setState({ message: this.props.message })
      }
    }
  }

  render() {
    const { onSubmitted, use, align, isPremium, locationHref, className } =
      this.props
    const {
      status,
      message,
      firstName,
      lastName,
      email,
      validEmail,
      acceptTerms,
    } = this.state

    const submitForm = (event: any) => {
      event?.preventDefault()
      if (email?.indexOf('@') > -1) {
        return onSubmitted({
          EMAIL: email || '',
        })
      }
    }

    if (status === 'success') {
      setTimeout(() => {
        gtagEvent({
          action: isPremium ? 'Newsletter/Premium' : 'Newsletter/Vinklubben',
          category: 'Signup',
          label: locationHref,
        })
        this.setState({
          status: '',
          message: '',
          firstName: '',
          lastName: '',
          email: '',
          validEmail: false,
          acceptTerms: false,
        })
      }, 6000)
    }
    if (status === 'error') {
      setTimeout(() => {
        this.setState({
          status: '',
          message: '',
          firstName: '',
          lastName: '',
          email: '',
          validEmail: false,
          acceptTerms: false,
        })
      }, 6000)
    }

    const { policy, button, subtitle } = this.props.texts

    return (
      <React.Fragment>
        {status !== 'success' && (
          <Flex
            as="form"
            className={[styles.form, className].join(' ')}
            flexDirection="column"
            justifyContent="center"
          >
            {use?.firstName && (
              <Flex
                className={styles.input}
                flexDirection="column"
                marginBottom={[4, null, null, 4]}
                width={1}
              >
                <input
                  type="text"
                  id="firstName"
                  name="firstName"
                  aria-label="Förnamn"
                  placeholder="Förnamn"
                  value={firstName}
                  onChange={(e) =>
                    this.setState({
                      firstName: e.target.value,
                    })
                  }
                  className={!firstName ? styles.error : ''}
                />
                <label htmlFor="firstName" />
              </Flex>
            )}
            {use?.lastName && (
              <Flex
                className={styles.input}
                flexDirection="column"
                marginBottom={[4, null, null, 4]}
              >
                <input
                  type="text"
                  id="lastName"
                  name="lastName"
                  aria-label="Efternamn"
                  placeholder="Efternamn"
                  value={lastName}
                  onChange={(e) =>
                    this.setState({
                      lastName: e.target.value,
                    })
                  }
                  className={!lastName ? styles.error : ''}
                />
                <label htmlFor="lastName" />
              </Flex>
            )}
            <Flex className={styles.input} flexDirection="column">
              <input
                type="email"
                id="email"
                name="email"
                aria-label="E-post"
                placeholder="E-post"
                value={email}
                onChange={this.validateEmail}
                className={!validEmail ? styles.error : ''}
              />
              <label htmlFor="email" />
            </Flex>
            {subtitle && <span className={styles.subtitle}>{subtitle}</span>}
            <Box alignSelf={align} marginTop={[6, null, null, 6]}>
              <CheckBox
                name="terms"
                checked={acceptTerms}
                onChange={() =>
                  this.setState({
                    acceptTerms: !acceptTerms,
                  })
                }
              >
                {policy && <Markdown source={policy} />}
              </CheckBox>
            </Box>
            <Box alignSelf={align} marginTop={[6, null, null, 6]}>
              <ButtonBorder
                className={styles.submit}
                disabled={
                  (!firstName && use?.firstName) ||
                  (!lastName && use?.lastName) ||
                  !validEmail ||
                  !acceptTerms
                }
                onClick={(e) => submitForm(e)}
              >
                {status === 'sending' && 'Skickar'}
                {status === 'error' && 'Något gick fel'}
                {!status && button}
              </ButtonBorder>
            </Box>
          </Flex>
        )}
        {message && (
          <Box marginTop={[4, null, null, 4]} width={1}>
            <div
              className={[
                styles.text,
                status === 'error' ? styles.error : '',
              ].join(' ')}
              dangerouslySetInnerHTML={{
                __html:
                  status === 'error'
                    ? 'Något gick fel, testa igen'
                    : isPremium
                    ? 'Välkommen till Ward Wines Premium för exklusiva erbjudanden om allokerade viner och tidlösa ikoner - vi ses snart i din mejl!'
                    : 'Välkommen till Ward Wines & Vänner och vinets underbara värld - vi ses snart i din mejl!',
              }}
            />
          </Box>
        )}
      </React.Fragment>
    )
  }
}

const MailchimpForm = ({
  url,
  use = { firstName: true, lastName: true },
  texts,
  align = 'center',
  isPremium = false,
  className,
}: any) => {
  const locationHref = useLocation ? useLocation().href : ''
  return url ? (
    <MailchimpSubscribe
      url={url}
      render={({
        subscribe,
        status,
        message,
      }: {
        subscribe: any
        status: string
        message: string
      }) => (
        <CustomForm
          className={className}
          align={align}
          status={status}
          message={message}
          onSubmitted={(formData: any) => subscribe(formData)}
          use={use}
          texts={texts}
          isPremium={isPremium}
          locationHref={locationHref}
        />
      )}
    />
  ) : null
}

export default MailchimpForm
